<script setup lang="ts">
import { ClockIcon } from "@heroicons/vue/24/outline";

const { t } = useI18n();
const localPath = useLocalePath();
const dayjs = useDayjs();
const { $client } = useNuxtApp();

const { data } = await $client.stripe.status.useQuery();

// useIntervalFn(() => {
//   refresh();
// }, 5000);
</script>

<template>
  <div class="mt-5 flex justify-center">
    <div class="h-9 w-full lg:w-7/12">
      <div
        v-if="!data?.isActive || data?.isTrial"
        class="inline-flex h-full w-full items-center justify-center gap-x-3 rounded-lg"
        :class="[data?.isTrial ? 'bg-orange-100' : 'bg-red-200']"
      >
        <ClockIcon class="h-6 w-6 stroke-icon" />
        <template v-if="data?.isTrial && data?.isActive">
          <p>
            {{ dayjs(data?.periodEnd).diff(new Date(), "day") }}
            {{
              dayjs(data?.periodEnd).diff(new Date(), "day") === 1
                ? t("stripe.test.single.remaining")
                : t("stripe.test.multi.remaining")
            }}
            {{ t("stripe.test.title") }} -
            <NuxtLink
              class="cursor-pointer font-bold hover:underline"
              :to="localPath('/products/tarif-waehlen')"
            >
              {{ t("stripe.link") }}
            </NuxtLink>
          </p>
        </template>
        <template v-else-if="data.isTrial && !data.isActive">
          <!--          testphase abgelaufen-->
          <p>
            {{ t("stripe.noTest.title") }} -
            <NuxtLink
              class="cursor-pointer font-bold hover:underline"
              :to="localPath('/products/tarif-waehlen')"
            >
              {{ t("stripe.link") }}
            </NuxtLink>
          </p>
        </template>
        <template v-else>
          <p>
            {{ t("stripe.noSubscription.title") }} -
            <NuxtLink
              class="cursor-pointer font-bold hover:underline"
              :to="localPath('/products/tarif-waehlen')"
            >
              {{ t("stripe.link") }}
            </NuxtLink>
          </p>
        </template>
      </div>
      <!-- {{ data }} -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChevronUpIcon, PlusIcon } from "@heroicons/vue/24/solid";
import {
  ChatBubbleOvalLeftIcon,
  Cog6ToothIcon,
  CubeTransparentIcon,
  ChartPieIcon,
  WrenchIcon,
} from "@heroicons/vue/20/solid";

const { t, locale } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const localPath = useLocalePath();
const { $client } = useNuxtApp();
const { signOut } = useAuth();
const route = useRoute();
const router = useRouter();
const flags = ref([
  {
    name: t("pages.edit.languages.de-DE"),
    value: "de-DE",
    locale: "de",
    flag: "/flags/de.svg",
  },
  {
    name: t("pages.edit.languages.en-GB"),
    value: "en-GB",
    locale: "en",
    flag: "/flags/gb.svg",
  },
]);

const { data: bots } = await $client.bot.list.useQuery();
const refresh = async () => {
  bots.value = await $client.bot.list.query();
};
const { data: user } = await $client.user.detail.useQuery();
const refreshUser = async () => {
  user.value = await $client.user.detail.query();
};

const currentBot = ref(route.params.activeBotId as string);

const botSelectorTab = ref<"INACTIVE" | "ACTIVE">("INACTIVE");

const selectedBot = computed(() => {
  return bots.value?.find((bot: any) => bot.id === currentBot.value);
});

watch(
  () => route.params,
  () => {
    if (route.params.activeBotId === "undefined" && bots.value?.length) {
      console.log("here");
      console.log("route.params", route.params);
      currentBot.value = bots.value[0].id;
      router.replace(route.fullPath.replace("undefined", currentBot.value));
      // currentBot.value = localStorage.getItem("previous-bot") || "undefined";
      // route.params.activeBotId = currentBot.value;
    }

    if (route.params.activeBotId && route.params.activeBotId !== "undefined") {
      currentBot.value = route.params.activeBotId as string;
      localStorage.setItem("previous-bot", currentBot.value);
    }

    botSelectorTab.value =
      selectedBot.value?.status === "ACTIVE" ? "ACTIVE" : "INACTIVE";
    refresh();
    refreshUser();
  },
  { immediate: true }
);

const getCurrentBot = computed(() => {
  if (currentBot.value) return currentBot.value;
  return localStorage.getItem("previous-bot") || "undefined";
});

const repalceBotPath = (path: string, replacement: string) => {
  if (path.startsWith("/bots/"))
    return path.replace(/^\/bots\/[a-z0-9]+/gm, "/bots/" + replacement);
  else return path;
};

const updateCurrentBot = (botId: string) => {
  currentBot.value = botId;
  localStorage.setItem("previous-bot", botId);
};

// const changeUserRole = async (role: "ADMIN" | "USER" | "VIEWER") => {
//   await $client.user.changeRole.mutate({
//     role,
//   });
//   // refreshUser();
//   window.location.reload();
// };

const openSettings = () => {
  router.replace({ query: { userSetting: "ACCOUNT", ...route.query } });
};

const closeSettings = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userSetting, ...query } = route.query;

  navigateTo(
    localPath({
      path: route.path,
      query,
      replace: true,
    })
  );
};
</script>

<template>
  <div class="custom-base">
    <nav class="">
      <div
        class="flex h-14 w-full justify-between gap-x-10 rounded-full bg-white px-4 py-2.5 shadow-md"
      >
        <div class="flex h-full basis-1/3 items-center">
          <img
            class="h-[28.797px] w-[168.867px] object-contain"
            src="/logo-aifinyochat-full-on-light.png"
            alt="/logo-aifinyochat-full-on-light.png"
          />
        </div>
        <div class="hidden h-full w-full basis-1/3 justify-center lg:flex">
          <div class="inline-flex gap-2">
            <CustomPillNav :to="localPath(`/bots/${getCurrentBot}/bots`)" exact>
              <Cog6ToothIcon class="mr-2 h-4 w-4 fill-icon-inactive" />
              <p class="text-sm">{{ t("header.bots") }}</p>
            </CustomPillNav>
            <CustomPillNav
              :to="localPath(`/bots/${getCurrentBot}/bots/edit`)"
              exact
            >
              <WrenchIcon class="mr-2 h-4 w-4 fill-icon-inactive" />
              <p class="text-sm">{{ t("header.workspace") }}</p>
            </CustomPillNav>
            <CustomPillNav
              :to="localPath(`/bots/${getCurrentBot}/conversations`)"
            >
              <ChatBubbleOvalLeftIcon class="mr-2 h-4 w-4 fill-icon-inactive" />
              <p class="text-sm">{{ t("header.conversations") }}</p>
            </CustomPillNav>
            <CustomPillNav :to="localPath(`/bots/${getCurrentBot}`)" exact>
              <ChartPieIcon class="mr-2 h-4 w-4 fill-icon-inactive" />
              <p class="text-sm">{{ t("header.overview") }}</p>
            </CustomPillNav>
          </div>
        </div>
        <div class="flex h-full w-full basis-1/3 justify-end">
          <div
            class="z-10 inline-flex w-fit max-w-full flex-none items-center gap-x-4"
          >
            <HeadlessPopover
              v-slot="{ open }"
              class="relative inline-block h-full"
            >
              <HeadlessPopoverButton
                class="inline-flex h-full max-w-[300px] flex-row items-center justify-center gap-2 truncate rounded-full bg-tertiary px-2.5 outline-none hover:bg-tertiary-hover"
                data-testid="navBotSelector"
              >
                <CubeTransparentIcon class="h-4 w-4 fill-black" />
                <p>{{ t("header.botFilter") }}</p>
                <template
                  v-if="
                    selectedBot !== undefined &&
                    !$route.fullPath.endsWith('/bots')
                  "
                >
                  <div class="h-7 w-px bg-zinc-300" />
                  <p class="truncate whitespace-nowrap">
                    <span class="truncate">{{ selectedBot?.name }} </span
                    ><span class="truncate text-badge-icon-inactive">
                      {{
                        selectedBot?.status === "ACTIVE"
                          ? "/produktiv"
                          : "/test"
                      }}
                    </span>
                  </p>
                </template>
                <ChevronUpIcon
                  class="h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80"
                  :class="{ 'rotate-180': open }"
                  aria-hidden="true"
                />
              </HeadlessPopoverButton>
              <HeadlessTransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <HeadlessPopoverPanel
                  v-slot="{ close }"
                  class="absolute right-1/2 z-10 mt-1 w-[223px] translate-x-1/2 divide-y divide-black divide-opacity-5 overflow-hidden rounded-md bg-white ring-1 ring-black ring-opacity-5 drop-shadow-md focus:outline-none"
                >
                  <div class="flex flex-col gap-y-1 p-1">
                    <div class="flex gap-x-1 rounded-md bg-tertiary p-1">
                      <button
                        class="flex h-8 flex-1 items-center justify-center rounded-md transition-all duration-150"
                        :class="[
                          botSelectorTab === 'INACTIVE'
                            ? 'bg-white text-primary drop-shadow-sm'
                            : 'hover:bg-tertiary-hover',
                        ]"
                        @click="botSelectorTab = 'INACTIVE'"
                      >
                        {{ t("header.botFilterTest") }}
                      </button>
                      <button
                        class="flex h-8 flex-1 items-center justify-center rounded-md transition-all duration-150"
                        :class="[
                          botSelectorTab === 'ACTIVE'
                            ? 'bg-white text-primary drop-shadow-sm'
                            : 'hover:bg-tertiary-hover',
                        ]"
                        @click="botSelectorTab = 'ACTIVE'"
                      >
                        {{ t("header.botFilterPublished") }}
                      </button>
                    </div>
                    <template
                      v-for="bot in bots?.filter((b:any) => b.status === botSelectorTab)"
                      :key="bot.id"
                    >
                      <NuxtLink
                        :to="localPath(repalceBotPath($route.fullPath, bot.id))"
                        class="flex h-[50px] w-full cursor-pointer items-center gap-3 rounded-md p-2"
                        :class="[
                          getCurrentBot === bot.id
                            ? 'bg-tertiary'
                            : 'hover:bg-tertiary',
                        ]"
                        @click="[updateCurrentBot(bot.id), close()]"
                      >
                        <div
                          class="aspect-square h-full rounded-md"
                          :style="{ backgroundColor: bot.color }"
                        />
                        <div class="truncate text-start">
                          <p class="truncate">
                            {{ bot.name || "" }}
                          </p>
                          <p class="truncate text-xs text-badge-icon/50">
                            {{ bot.url || "" }}
                          </p>
                        </div>
                      </NuxtLink>
                    </template>
                  </div>
                  <div class="p-1">
                    <ModalOuterWrapper
                      v-slot="{ open: openCreate, close: closeCreate, isOpen }"
                    >
                      <ModalCreateBot
                        :open="isOpen === 'create'"
                        @close="closeCreate()"
                      />
                      <CustomButton
                        type="custom"
                        class="flex h-10 w-full cursor-pointer items-center justify-center rounded-md hover:bg-badge-background"
                        test-id="openCreateBot"
                        @click="openCreate('create')"
                      >
                        <PlusIcon class="mr-2 h-4 w-4 fill-primary" />
                        <p class="text-sm text-primary">
                          {{ t("header.addBot") }}
                        </p>
                      </CustomButton>
                    </ModalOuterWrapper>
                  </div>
                </HeadlessPopoverPanel>
              </HeadlessTransitionChild>
            </HeadlessPopover>
            <HeadlessMenu
              as="div"
              class="relative inline-block h-full text-left"
            >
              <HeadlessMenuButton
                class="inline-flex h-full flex-row items-center justify-center rounded-full hover:bg-badge-background"
              >
                <div
                  class="flex aspect-square h-full items-center justify-center"
                >
                  <template v-for="flag in flags" :key="flag.locale">
                    <img
                      v-if="locale === flag.locale"
                      class="h-3 w-4"
                      :src="flag.flag"
                      :alt="flag.flag"
                    />
                  </template>
                </div>
              </HeadlessMenuButton>
              <HeadlessTransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <HeadlessMenuItems
                  class="absolute right-1/2 z-10 mt-1 w-fit translate-x-1/2 rounded-md bg-white p-2 ring-1 ring-black ring-opacity-5 drop-shadow-md focus:outline-none"
                >
                  <template v-for="i in flags" :key="i">
                    <HeadlessMenuItem v-slot="{ active }">
                      <nuxt-link
                        :class="[
                          'group flex h-9 w-8 items-center justify-start rounded-md px-2 py-2 text-sm ',
                          active ? 'bg-alternative' : '',
                        ]"
                        :to="switchLocalePath(i.locale)"
                      >
                        <img
                          class="h-3 w-4 flex-none"
                          :src="i.flag"
                          :alt="i.flag"
                        />
                      </nuxt-link>
                    </HeadlessMenuItem>
                  </template>
                </HeadlessMenuItems>
              </HeadlessTransitionChild>
            </HeadlessMenu>
            <HeadlessMenu
              v-slot="{ open }"
              as="div"
              class="relative inline-block h-full text-left"
            >
              <HeadlessMenuButton
                class="inline-flex h-full flex-row items-center justify-center rounded-full hover:bg-badge-background"
              >
                <UserImage class="h-full" :user="user" />
                <ChevronUpIcon
                  class="mx-2.5 h-5 w-5 text-violet-200 transition duration-150 ease-in-out hover:text-violet-100"
                  :class="{ 'rotate-180': open }"
                  aria-hidden="true"
                />
              </HeadlessMenuButton>
              <HeadlessTransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <HeadlessMenuItems
                  class="absolute right-1/2 z-10 mt-1 w-fit translate-x-1/2 rounded-md bg-white p-2 ring-1 ring-black ring-opacity-5 drop-shadow-md focus:outline-none"
                >
                  <HeadlessMenuItem v-slot="{ active }">
                    <button
                      :class="[
                        'group flex h-9 w-full items-center justify-start rounded-md px-2 py-2 text-sm',
                        active ? 'bg-alternative' : '',
                      ]"
                      @click="openSettings()"
                    >
                      {{ t("header.settings") }}
                    </button>
                  </HeadlessMenuItem>
                  <HeadlessMenuItem v-slot="{ active }">
                    <button
                      :class="[
                        'group flex h-9 w-full items-center justify-start rounded-md px-2 py-2 text-sm',
                        active ? 'bg-alternative' : '',
                      ]"
                      @click="
                        signOut({
                          callbackUrl: `${
                            locale === 'de' ? '' : `/${locale}`
                          }/auth/sign-in`,
                        })
                      "
                    >
                      {{ t("header.logout") }}
                    </button>
                  </HeadlessMenuItem>
                </HeadlessMenuItems>
              </HeadlessTransitionChild>
            </HeadlessMenu>
            <!-- </ModalOuterWrapper> -->
          </div>
        </div>
      </div>
    </nav>
    <div id="banner">
      <UtilsStripeBanner />
      <LazyUtilsStripeSuccessModal
        v-if="'stripeSuccess' in $route.query"
        :open="'stripeSuccess' in $route.query"
      />
    </div>
    <main>
      <slot />
    </main>
    <LazyModalUserSettings
      v-if="'userSetting' in $route.query"
      :open="'userSetting' in $route.query"
      @close="closeSettings()"
    />
  </div>
</template>

<style>
body {
  @apply bg-background;
}

.custom-base {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: minmax(9px, 80px) 1fr minmax(9px, 80px);
  grid-template-rows: 24px 56px 70px calc(100% - 24px - 49px - 70px - 24px) 24px;
  grid-template-areas:
    ". dev ."
    ". nav ."
    ". banner ."
    ". main ."
    ". . .";
}

#dev {
  grid-area: dev;
}

nav {
  grid-area: nav;
}

#banner {
  grid-area: banner;
}

main {
  grid-area: main;
}
</style>
